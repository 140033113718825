// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// import './src/styles/tailwind.css';
import React from 'react';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/command-line/prism-command-line.css';
import 'prismjs/plugins/command-line/prism-command-line.css';
import { ThemeProvider } from './src/utils';
import './src/styles/asciinema-player.css';

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
